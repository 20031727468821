<template>
  <div style="margin-top: 20px; text-align: left">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="top">
      <el-form-item label="鉴权码方式" prop="sentCodeMode">
        <el-select v-model="ruleForm.sentCodeMode" placeholder="请选择">
          <el-option label="邮箱" :value="0"></el-option>
          <el-option label="短信" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="播放器地址" prop="sentCodeMode">
        <el-input v-model="ruleForm.videoPlayerAdd"/>
      </el-form-item>
      <el-form-item label="代理地址" prop="requestProxy">
        <el-input v-model="ruleForm.requestProxy"/>
      </el-form-item>
      <el-form-item label="显示广告" prop="sentCodeMode">
        <el-select v-model="ruleForm.gGShow" placeholder="请选择">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SystemConfig from "@/request/SystemConfig";

export default {
  name: "SystemSet",
  data() {
    return {
      sysAsk: new SystemConfig(),
      ruleForm: {
        sentCodeMode: 0,
        videoPlayerAdd: "",
        gGShow: false,
        requestProxy: ""
      },
      rules: {
        sentCodeMode: [
          {required: true, message: '请选择鉴权码方式', trigger: 'change'}
        ]
      }
    };
  },
  mounted() {
    this.sysAsk.getSystemComConfig().then(res => {
      this.ruleForm = res
    })
  },
  methods: {
    submitForm(formName) {
      let this_ = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store.commit('systemConfig/setCommon', this.ruleForm)
          this_.sysAsk.setComConfig(this_.ruleForm).then(res => {
            if (res.code == 'success') {
              this_.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              });
            }
          })
        } else {
          this.$message({
            showClose: true,
            message: '填完',
            type: 'error'
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs.ruleForm.resetFields();
    }
  },
}
</script>

<style scoped>

</style>